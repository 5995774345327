// import package
import React, { useEffect, useState } from "react";
import Web3 from "web3";
// import material ui
import { Store, Accessibility, DevicesOtherSharp } from "@material-ui/icons";
import { Icon } from "@material-ui/core";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
// import componet
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardFooter from "../../components/Card/CardFooter.js";
import bosticAbi from "../../ABI/bostic.json";
import DueAbi from "../../ABI/DueAbi.json";
import config from "../../actions/config";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
// import action

import ReactApexChart from "react-apexcharts";
import {
  getcountApproved,
  getUsers,
  getsettingsData,
  getWithdrwaCounts,
  getPackageWiseUser,
  getEarningData,
  getPaidAmounts,
  getChartData,
  getPackages,
} from "../../actions/admin";
import { Link } from "react-router-dom";

const DashboardCount = (props) => {
  // props
  const { classes } = props;

  // state

  const [network, setnetwork] = useState();
  const [balance, setbalance] = useState();
  const [addre, setAddre] = useState();
  const [tokenbalance, settokenbalance] = useState();
  const [duebal, setDuebal] = useState(0);
  const [Bosbal, setBosbal] = useState(0);
  const [Bosbal1, setBosbal1] = useState(0);
  const [Duesymbol, setDuesymbol] = useState();
  const [Bossymbol, setBossymbol] = useState();
  const [DueTotalamount, setDueTotalamount] = useState(0);
  const [bosTotalamount, setbosTotalamount] = useState(0);
  const [Totalcount, setTotalcount] = useState();
  const [totalUsers, setTotalUsers] = useState(0);
  const [latestUsers, setLatestUsers] = useState(0);
  const [BosDolVal, setBosDolVal] = useState(0);
  const [DueDolVal, setDueDolVal] = useState(0);
  const [BosticValue, setBosticValue] = useState(0);
  const [DueValue, setDueValue] = useState(0);

  const [WithCount, setWithCount] = useState(0);
  const [WithCoin, setWithCoin] = useState(0);
  const [WithDueCount, setWithDueCount] = useState(0);
  const [WithDueCoin, setWithDueCoin] = useState(0);
  const [LatestBosCount, setLatestBosCount] = useState(0);
  const [LatestDueCount, setLatestDueCount] = useState(0);
  const [LatestBosCoin, setLatestBosCoin] = useState(0);
  const [LatestDueCoin, setLatestDueCoin] = useState(0);
  const [Active, setActive] = useState(0);
  const [InActive, setInActive] = useState(0);
  const [Plans, setPlans] = useState({
    plan1: 0,
    plan2: 0,
    plan3: 0,
    plan4: 0,
    plan5: 0,
    plan6: 0,
    plan7: 0,
  });
  const [PlanName, setPlanName] = useState({
    plan1: 0,
    plan2: 0,
    plan3: 0,
    plan4: 0,
    plan5: 0,
    plan6: 0,
    plan7: 0,
  });
  const [allUserData, setAllUaerData] = useState([]);
  const [Earnings, setEarnings] = useState({
    totalAmt: 0,
    paidAmt: 0,
    unpaidAmt: 0,
    pending: 0,
    declain: 0,
    allUAmt: 0,
  });
  const [paidAmtBos, setPaidAmtbos] = useState(0);
  const [paidAmtDue, setPaidAmtdue] = useState(0);
  const [getMonths, setGetMonths] = useState([]);
  const [getChartBos, setChartBos] = useState([]);
  const [getChartDue, setChartDue] = useState([]);
  const [options, setOptions] = useState();
  const [series, setSeries] = useState();

  // const classes1 = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    init();
    getdata();
    getUsersData();
    getsettings();
    getWithdrwaCount();
    getPackageWiseUsr();
    getEarning();
    getPaidAmount();
    getPackageNames();
  }, []);

  useEffect(() => {
    getCharts();
  }, [Active]);
  var BosValue;
  var Due_Value;

  const getPackageNames = async () => {
    const data = await getPackages();
    setPlanName({
      ...PlanName,
      plan1: data && data.result && data.result.result[0].planAmount,
      plan2: data && data.result && data.result.result[1].planAmount,
      plan3: data && data.result && data.result.result[2].planAmount,
      plan4: data && data.result && data.result.result[3].planAmount,
      plan5: data && data.result && data.result.result[4].planAmount,
      plan6: data && data.result && data.result.result[5].planAmount,
      plan7: data && data.result && data.result.result[6].planAmount,
    });
  };

  const getCharts = async () => {
    const data = await getChartData();
    var Arrbos = [];
    var ArrDue = [];
    var ArrMon = [];
    data.result.result.forEach((ele) => {
      ArrMon.push(ele.Month + " " + ele._id.year);
      Arrbos.push(ele.totalBosAmt);
      ArrDue.push(ele.totalDueAmt);
    });
    setChartBos(Arrbos);
    setChartDue(ArrDue);
    setGetMonths(ArrMon);

    setSeries([
      {
        name: "Bostic Coin ($)",
        data: Arrbos,
      },
      {
        name: "Due Coin",
        data: ArrDue,
      },
    ]);

    if (ArrMon && ArrMon.length > 0) {
      setOptions({
        chart: {
          type: "bar",
          height: 380,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ArrMon,
        },
        yaxis: {
          title: {
            text: "$ (Coins Value)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "" + val;
            },
          },
        },
      });
    }
  };
  const getPaidAmount = async () => {
    const web3 = new Web3(Web3.givenProvider);
    const address = await web3.eth.requestAccounts();
    const data = await getPaidAmounts();
    if (config.adminaddress === address[0]) {
      setPaidAmtbos(
        data.result.DataPaidBos.length > 0
          ? data.result.DataPaidBos[0]?.amount
          : 0
      );
      setPaidAmtdue(
        data.result.DataPaidDue.length > 0
          ? data.result.DataPaidDue[0]?.amount
          : 0
      );
    }
  };

  const getWithdrwaCount = async () => {
    const data = await getWithdrwaCounts();
    setWithCount(data.result1.DataBos?.length);
    setWithCoin(data.result1.DataBosSum[0]?.amount);
    setWithDueCount(data.result1.DataDue.length);
    setWithDueCoin(data.result1.DataDueSum[0]?.amount);
    setLatestBosCount(data.result1.DataBosLast24.length);
    setLatestBosCoin(data.result1.DataBosLast24Sum[0]?.amount);
    setLatestDueCount(data.result1.DataDueLast24.length);
    setLatestDueCoin(data.result1.DataDueLast24Sum[0]?.amount);
  };
  const getsettings = async () => {
    let data = await getsettingsData();

    setBosticValue(data.result[0].bosticValue);
    BosValue = data.result[0].bosticValue;
    setDueValue(data.result[0].dueValue);
    Due_Value = data.result[0].dueValue;
  };

  const getPackageWiseUsr = async () => {
    const data = await getPackageWiseUser();
    setPlans({
      ...Plans,
      plan1: data && data.result1 && data.result1.Plan1?.length,
      plan2: data && data.result1 && data.result1.Plan2?.length,
      plan3: data && data.result1 && data.result1.Plan3?.length,
      plan4: data && data.result1 && data.result1.Plan4?.length,
      plan5: data && data.result1 && data.result1.Plan5?.length,
      plan6: data && data.result1 && data.result1.Plan6?.length,
      plan7: data && data.result1 && data.result1.Plan7?.length,
    });
  };

  const getUsersData = async () => {
    let data = await getUsers();

    setTotalUsers(data.result.length);
    setLatestUsers(data.result1.result1.length);
    setActive(data.result1.Active?.length);
    setInActive(data.result1.inActive?.length);
    setAllUaerData(data.result.filter((val) => val.userID != 1));
  };

  const getdata = async () => {
    let countdata = await getcountApproved();

    setbosTotalamount(countdata?.result?.bos[0]);

    setDueTotalamount(countdata?.result?.due[0]);
    setTotalcount(countdata?.result?.totalcount[0]);
  };

  const init = async () => {
    const web3 = new Web3(Web3.givenProvider);
    const address = await web3.eth.requestAccounts();
    setAddre(address[0]);

    const bosticContract = new web3.eth.Contract(
      bosticAbi,
      config.bostic_contract
    );

    let bosbal = await bosticContract.methods.balanceOf(address[0]).call();
    let symbolbos = await bosticContract.methods.symbol().call();

    bosbal = web3.utils.fromWei(bosbal);
    bosbal = Number(bosbal);
    setBosbal1(bosbal);
    if (config.adminaddress === address[0]) {
      setBosbal(bosbal);
    } else {
      setBosbal(0);
    }
    setBossymbol(symbolbos);
    setBosDolVal(bosbal / BosValue);

    const dueContract = new web3.eth.Contract(DueAbi, config.Duecoin_contract);

    let duebal = await dueContract.methods.balanceOf(address[0]).call();
    let symbol = await dueContract.methods.symbol().call();

    duebal = web3.utils.fromWei(duebal);
    duebal = Number(duebal);
    if (config.adminaddress === address[0]) {
      setDuebal(duebal);
    } else {
      setDuebal(0);
    }
    setDuesymbol(symbol);
    setDueDolVal(duebal / Due_Value);
  };

  const getEarning = async () => {
    const data = await getEarningData();
    setEarnings({
      totalAmt: data.result.Data[0]?.allAmount,
      paidAmt: data.result.DataPaid[0]?.allPaidAmount,
      unpaidAmt: data.result.DataUnpaid[0]?.allUnpaidAmount,
      pending: data.result.DataReject[0]?.allpendAmount,
      declain: data.result.DataDeclain[0]?.alldecAmount,
      allUAmt: data.result.DataAll[0]?.alluserAmount,
    });
  };

  const PushWith = () => {
    window.location.href = "/bosticadmin/withdrawrequest";
  };
  const PushWithUsers = () => {
    window.location.href = "/bosticadmin/users";
  };
  const PushWithPlans = () => {
    window.location.href = "/bosticadmin/plan-details";
  };

  const columns = [
    { id: "id", label: "S.No.", minWidth: 50 },
    { id: "uid", label: "UserID", minWidth: 80 },
    {
      id: "name",
      label: "Name",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "size",
      label: "Refers",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "plan",
      label: "Plan",
      minWidth: 100,
      align: "left",
      format: (value) => value,
    },
    {
      id: "bosval",
      label: "Bostic Value",
      minWidth: 100,
      align: "left",
      format: (value) => value,
    },
  ];

  function createData(id, uid, name, size, plan, bosval) {
    // const density = population / size;
    return { id, uid, name, size, bosval, plan };
  }

  const rows = allUserData.map((ele, index) =>
    createData(
      index + 1,
      ele.userID,
      ele.userName,
      ele.referral.length,
      ele.plan == 1
        ? PlanName.plan1
        : ele.plan == 2
        ? PlanName.plan2
        : ele.plan == 3
        ? PlanName.plan3
        : ele.plan == 4
        ? PlanName.plan4
        : ele.plan == 5
        ? PlanName.plan5
        : ele.plan == 6
        ? PlanName.plan6
        : PlanName.plan7,
      ele.referealAmtBos
    )
  );
  var dueBalance = duebal - paidAmtDue;

  return (
    <GridContainer>
      {/*<GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                            <Icon>content_copy</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>User</p>
                        <h3 className={classes.cardTitle + " card_title"}>
                            {countData.userCount}
                        </h3>
                    </CardHeader>
                    <CardFooter stats></CardFooter>
                </Card> 
            </GridItem>*/}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardIcon color="info" style={{ marginRight: "0px" }}>
            <h4 className={classes.cardTitleWhite}>Coins</h4>
          </CardIcon>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Bostic Balance</p>
            <br />
            <br />
            <h3 className={classes.cardTitle + " card_title"}>
              {Bosbal ? Bosbal.toFixed(2) : 0} : {paidAmtBos ? paidAmtBos : 0}
            </h3>
            {/* <span style={{ color: "#3C4858" }}>
              $ {BosDolVal ? BosDolVal : 0}
            </span> */}
          </CardHeader>
          <CardFooter stats></CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Due Balance</p>
            <br />
            <br />
            <h3 className={classes.cardTitle + " card_title"}>
              {duebal ? duebal.toFixed(2) : 0} : {paidAmtDue ? paidAmtDue : 0}
            </h3>
            {/* <span style={{ color: "#3C4858" }}>
              $ {DueDolVal ? DueDolVal : 0}
            </span> */}
          </CardHeader>
          <CardFooter stats> </CardFooter>
        </Card>
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={3}>
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <PeopleOutlineOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Total Users</p>
            <br />
            <br />
            <h3 className={classes.cardTitle + " card_title"}>Users:{totalUsers}</h3>
          </CardHeader>
          <CardFooter stats></CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <PeopleOutlineOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Last 24 Hrs New Commer</p>
            <br />
            <br />
            <h3 className={classes.cardTitle + " card_title"}>New Users:{latestUsers}</h3>
          </CardHeader>
          <CardFooter stats></CardFooter>
        </Card>
      </GridItem> */}

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardIcon color="info" style={{ marginRight: "0px" }}>
            <h4 className={classes.cardTitleWhite}>Withdraw</h4>
          </CardIcon>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger" style={{ marginTop: "0px" }}>
              <PeopleOutlineOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Bostic Count</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {WithCount ? WithCount : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Bostic Coins</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {WithCoin ? WithCoin : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger" style={{ marginTop: "0px" }}>
              <PeopleOutlineOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Last 24Hr. Bostic Count</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {LatestBosCount ? LatestBosCount : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Last 24Hr. Bostic Coins</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {LatestBosCoin ? LatestBosCoin : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <PeopleOutlineOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Due Count</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {WithDueCount ? WithDueCount : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Due Coins</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {WithDueCoin ? WithDueCoin : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <PeopleOutlineOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Last 24Hr. Due Count</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {LatestDueCount ? LatestDueCount : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Last 24Hr. Due Coins</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {LatestDueCoin ? LatestDueCoin : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardIcon color="info" style={{ marginRight: "0px" }}>
            <h4 className={classes.cardTitleWhite}>Users</h4>
          </CardIcon>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWithUsers}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger" style={{ marginTop: "0px" }}>
              <PeopleOutlineOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Count</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {totalUsers ? totalUsers : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          onClick={PushWithUsers}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <PeopleOutlineOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Active / Exit</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {Active + " / " + InActive}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={6} sm={2} md={2}>
        <Card
          onClick={PushWithPlans}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            background: "#e53935",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: "17px",
              borderBottom: "1px solid #ffffff",
              paddingBottom: "8px",
              fontWeight: "600",
            }}
            className={classes.cardCategory}
          >
            ${PlanName.plan1}
          </p>
          <h4
            style={{
              textAlign: "center",
              color: "#ffffff",
              paddingTop: "10px",
            }}
            className={classes.cardTitle}
          >
            {Plans.plan1}
          </h4>
        </Card>
      </GridItem>
      <GridItem xs={6} sm={2} md={2}>
        <Card
          onClick={PushWithPlans}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            background: "#e53935",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: "17px",
              borderBottom: "1px solid #ffffff",
              paddingBottom: "8px",
              fontWeight: "600",
            }}
            className={classes.cardCategory}
          >
            ${PlanName.plan2}
          </p>
          <h4
            style={{
              textAlign: "center",
              color: "#ffffff",
              paddingTop: "10px",
            }}
            className={classes.cardTitle}
          >
            {Plans.plan2}
          </h4>
        </Card>
      </GridItem>
      <GridItem xs={6} sm={2} md={2}>
        <Card
          onClick={PushWithPlans}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            background: "#e53935",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: "17px",
              borderBottom: "1px solid #ffffff",
              paddingBottom: "8px",
              fontWeight: "600",
            }}
            className={classes.cardCategory}
          >
            ${PlanName.plan3}
          </p>
          <h4
            style={{
              textAlign: "center",
              color: "#ffffff",
              paddingTop: "10px",
            }}
            className={classes.cardTitle}
          >
            {Plans.plan3}
          </h4>
        </Card>
      </GridItem>
      <GridItem xs={6} sm={2} md={2}>
        <Card
          onClick={PushWithPlans}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            background: "#e53935",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: "17px",
              borderBottom: "1px solid #ffffff",
              paddingBottom: "8px",
              fontWeight: "600",
            }}
            className={classes.cardCategory}
          >
            ${PlanName.plan4}
          </p>
          <h4
            style={{
              textAlign: "center",
              color: "#ffffff",
              paddingTop: "10px",
            }}
            className={classes.cardTitle}
          >
            {Plans.plan4}
          </h4>
        </Card>
      </GridItem>
      <GridItem xs={6} sm={2} md={2}>
        <Card
          onClick={PushWithPlans}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            background: "#e53935",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: "17px",
              borderBottom: "1px solid #ffffff",
              paddingBottom: "8px",
              fontWeight: "600",
            }}
            className={classes.cardCategory}
          >
            ${PlanName.plan5}
          </p>
          <h4
            style={{
              textAlign: "center",
              color: "#ffffff",
              paddingTop: "10px",
            }}
            className={classes.cardTitle}
          >
            {Plans.plan5}
          </h4>
        </Card>
      </GridItem>
      <GridItem xs={6} sm={2} md={2}>
        <Card
          onClick={PushWithPlans}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            background: "#e53935",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: "17px",
              borderBottom: "1px solid #ffffff",
              paddingBottom: "8px",
              fontWeight: "600",
            }}
            className={classes.cardCategory}
          >
            ${PlanName.plan6}
          </p>
          <h4
            style={{
              textAlign: "center",
              color: "#ffffff",
              paddingTop: "10px",
            }}
            className={classes.cardTitle}
          >
            {Plans.plan6}
          </h4>
        </Card>
      </GridItem>
      <GridItem xs={6} sm={2} md={2}>
        <Card
          onClick={PushWithPlans}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            background: "#e53935",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontSize: "17px",
              borderBottom: "1px solid #ffffff",
              paddingBottom: "8px",
              fontWeight: "600",
            }}
            className={classes.cardCategory}
          >
            ${PlanName.plan7}
          </p>
          <h4
            style={{
              textAlign: "center",
              color: "#ffffff",
              paddingTop: "10px",
            }}
            className={classes.cardTitle}
          >
            {Plans.plan7}
          </h4>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardIcon color="info" style={{ marginRight: "0px" }}>
            <h4 className={classes.cardTitleWhite}>Earnings (Bostic)</h4>
          </CardIcon>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          // onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Total Earnings</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {Earnings.totalAmt ? Earnings.totalAmt + Earnings.allUAmt : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          // onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Total Paid</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {Earnings.paidAmt ? Earnings.paidAmt : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          // onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Total Unpaid</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {Earnings.unpaidAmt ? Earnings.unpaidAmt.toFixed(2) : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          // onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Total Pendings</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {Earnings.pending ? Earnings.pending.toFixed(2) : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          // onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Total Declain</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {Earnings.declain ? Earnings.declain.toFixed(2) : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Card
          // onClick={PushWith}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
          <CardHeader color="success" stats icon>
            <CardIcon color="success" style={{ marginTop: "0px" }}>
              <MonetizationOnOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Wallet Balance</p>
            <h3 className={classes.cardTitle + " card_title"}>
              {Bosbal1 ? Bosbal1.toFixed(2) : 0}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardIcon color="info" style={{ marginRight: "0px" }}>
            <h4 className={classes.cardTitleWhite}>Referral</h4>
          </CardIcon>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>
        {/* DataTable */}
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} style={{ marginTop: "-30px" }}>
        {/* Chart */}
        <Card>
          {options && (
            <>
              <ReactApexChart
                options={options && options}
                series={series}
                type="bar"
                height={350}
              />
            </>
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default DashboardCount;
