import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import jwt_decode from "jwt-decode";
import Web3 from "web3";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import config from "../../actions/config";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import action
import { setCurrentUser, changepassword, checkOtp } from "../../actions/admin";

// import lib
import validation from "../ChnagePass/Validation1";
import isEmpty from "../../lib/isEmpty";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textDanger: {
    color: "#f32828",
  },
}));
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  email: "",
  password: "",
  Newpassword: "",
  confirmPassword: "",
  otp: "",
};

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [changes, setchanges] = useState(false);

  const { email, password, Newpassword, confirmPassword, otp } = formValue;

  // function
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    var paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if (Newpassword != confirmPassword) {
      toast.error("New Password & confirm password not match", toasterOption);
      return false;
    }
    if (isEmpty(Newpassword)) {
      toast.error("Plese enter your new Password", toasterOption);
      return false;
    } else if (!paswd.test(Newpassword)) {
      toast.error(
        "7 to 15 Characters which Contain at least one Numeric Digit and a Special Character",
        toasterOption
      );
      return false;
    }
    if (isEmpty(confirmPassword)) {
      toast.error("Plese enter your Confirm Password", toasterOption);
      return false;
    }
    if (isEmpty(otp)) {
      toast.error("Plese enter your OTP", toasterOption);
      return false;
    }

    var token = localStorage.getItem("admin_token");
    token = token.replace("Bearer ", "");
    const decoded = jwt_decode(token);
    let reqData = {
      _id: decoded._id,
      password,
      Newpassword,
      confirmPassword,
      otp,
    };

    let { message, error, result } = await checkOtp(reqData, dispatch);
    console.log(error);

    if (!isEmpty(message)) {
      toast.error(message, toasterOption);
      return false;
    } else if (isEmpty(error)) {
      setFormValue(initialFormValue);
      history.push("/queenbotsAdmin/Dashboard");
      dispatch(setCurrentUser(result));
      setchanges(true);
      toast.success("Password Changed Successfully", toasterOption);
    } else {
      toast.error(message, toasterOption);
      setValidateError(error);
    }
  };

  const handleFormSubmit1 = async (e) => {
    e.preventDefault();

    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();

    // if (accounts != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }

    let reqData = {
      email,
      password,
    };
    let { error, result } = await changepassword(reqData, dispatch);
    console.log(error);

    if (isEmpty(error)) {
      setFormValue(initialFormValue);
      toast.success("OTP has been send to you Mail address", toasterOption);
      dispatch(setCurrentUser(result));
      setchanges(true);
    } else {
      toast.error(error.password, toasterOption);
      setValidateError(error);
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  useEffect(() => {
    setValidateError(validation(formValue));
  }, []);

  // console.log(formValue,'formValueformValue')
  return (
    <Container component="main" maxWidth="xs" style={{overflow:'scroll !important'}}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="email"
            disabled={changes == true ? true : false}
            autoFocus
          />
          {changes == true ? (
            ""
          ) : (
            <div>
              {" "}
              {toched.email && validateError.email && (
                <p style={{ color: "#ff1212" }} className="error-message">
                  {validateError.email}
                </p>
              )}{" "}
            </div>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            label=" Old Password"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={changes == true ? true : false}
          />
          {changes == true ? (
            ""
          ) : (
            <div>
              {toched.password && validateError.password && (
                <p style={{ color: "#ff1212" }} className="error-message">
                  {validateError.password}
                </p>
              )}{" "}
            </div>
          )}

          {changes == true ? (
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="otp"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Enter Your Otp"
                type="text"
                id="otp"
                autoComplete="current-password"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="Newpassword"
                onChange={handleChange}
                onBlur={handleBlur}
                label="New Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {toched.Newpassword && validateError.Newpassword && (
                <p style={{ color: "#ff1212" }} className="error-message">
                  {validateError.Newpassword}
                </p>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
              />
              {toched.confirmPassword && validateError.confirmPassword && (
                <p style={{ color: "#ff1212" }} className="error-message">
                  {validateError.confirmPassword}
                </p>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleFormSubmit}
                // disabled={!isEmpty(validateError)}
              >
                Update
              </Button>
            </div>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!isEmpty(validateError)}
              onClick={handleFormSubmit1}
            >
              Confirm
            </Button>
          )}
          <Grid container>
            <Grid item xs>
              {/* <Link href="/Forgot" variant="body2">
                Forgot password?
              </Link> */}
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
}
