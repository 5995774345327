import React, { useState, useEffect } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Blink from "react-blink-text";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Tasks from "../../components/Tasks/Tasks.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import Danger from "../../components/Typography/Danger.js";
import Card from "../../components/Card/Card.js";

import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import DashboardCount from "../../components/DashboardCount";
import Badge from "@material-ui/core/Badge";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Typography from "@material-ui/core/Typography";
// import './dashboard.module.css'
// import '../../assets/css';

import Web3 from "web3";


import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { getwithdrawrequest } from "../../actions/admin";

import { toast } from "react-toastify";
import config from "../../actions/config";
import { Link } from "react-router-dom";
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [count, setcount] = useState("");

  const acc = async () => {
    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();

    // if (accounts != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    // }
  };

  useEffect(() => {
    withdrawreq();
    acc();
  }, []);

  const withdrawreq = async () => {
    const data = await getwithdrawrequest();
    setcount(data.result.Data.length);
  };

  const request = (
    <div>
      <GridItem xs={1} sm={1} md={3}>
        <Card>
          <Link to="/withdrawrequest">
            <CardIcon color="warning">
              <h6>Withdraw Request : {count} </h6>
            </CardIcon>
          </Link>
        </Card>
      </GridItem>
    </div>
  );

  const defaultProps = {
    color: "secondary",
    children: <LocalAtmIcon />,
  };

  return (
    <div>
      {/* {count != 0 ? (
        <Card className="Tets">
          <Blink color="white" text={`Withdraw Request:${count}`} fontSize="20">
            Withdraw Request : {count}
          </Blink>
        </Card>
      ) : (
        ""
      )} */}
      <Link
        to="/withdrawrequest"
        style={{ textDecoration: "none", color: "#333333" }}
      >
        {count != 0 ? (
          <div
            style={{
              padding: "22px",
              boxShadow: "0 0 10px 0 #2222",
              // width: "20%",
              textAlign: "center",
              marginBottom: "20px",
            }}
            className="WithReq"
          >
            <Badge>
              <Typography>Withdraw Request :</Typography>
            </Badge>{" "}
            <Badge badgeContent={count} {...defaultProps} />
          </div>
        ) : (
          ""
        )}
      </Link>

      <DashboardCount classes={classes} />
    </div>
  );
}
