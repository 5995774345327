import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
// import dexbot from "../../ABI/dexbot.json";
import config from "../../actions/config";
import { getsettingsData, updatesettings } from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  directreferal: "",
  bosticbalancedPercentage: "",
  dueBalancedPercentage: "",
  companyAmount: "",
  minimumWithdrawBos: "",
  minimumWithdrawDue: "",
  maxReachLimit: "",
  bosticValue: "",
  dueValue: "",
  gracePeriod: "",
};
const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const [Adirectreferal, setdirectreferal] = useState("");
  const [AbosticbalancedPercentage, setbosticbalancedPercentage] = useState("");
  const [AdueBalancedPercentage, setdueBalancedPercentage] = useState("");
  const [AcompanyAmount, setcompanyAmount] = useState("");
  const [AminimumWithdrawBos, setminimumWithdrawBos] = useState("");
  const [AminimumWithdrawDue, setminimumWithdrawDue] = useState("");
  const [AmaxReachLimit, setmaxReachLimit] = useState("");
  const [AbosticValue, setbosticValue] = useState("");
  const [AdueValue, setdueValue] = useState("");
  const [AgracePeriod, setgracePeriod] = useState("");
  const [bidClaim, setbidClaim] = useState(0);
  const [dueLimit,setdueLimit] = useState(0)
  const [id, setid] = useState();
  const [Account, setAccount] = useState();

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === "directreferal") {
      const { id, value } = e.target;
      setdirectreferal(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "bosticbalancedPercentage") {
      const { id, value } = e.target;
      setbosticbalancedPercentage(value);

      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "dueBalancedPercentage") {
      const { id, value } = e.target;
      setdueBalancedPercentage(value);

      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "duetreAmt") {
      const { id, value } = e.target;
      setcompanyAmount(value);

      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "minimumWithdrawBos") {
      const { id, value } = e.target;
      setminimumWithdrawBos(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "minimumWithdrawDue") {
      const { id, value } = e.target;
      setminimumWithdrawDue(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "maxReachLimit") {
      const { id, value } = e.target;
      setmaxReachLimit(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "gracePeriod") {
      const { id, value } = e.target;

      setgracePeriod(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "bosticValue") {
      const { id, value } = e.target;

      setbosticValue(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    } else if (e.target.name === "bidClaim") {
      const { id, value } = e.target;
      setbidClaim(value);
      let formDatass = { ...formData, ...{ [id]: value } };
    } else if (e.target.name === "dueLimit") {
      const { id, value } = e.target;
      setdueLimit(value);
      let formDatass = { ...formData, ...{ [id]: value } };
    } else if (e.target.name === "dueValue") {
      const { id, value } = e.target;
      setdueValue(value);
      // setValidateError({});
      let formDatass = { ...formData, ...{ [id]: value } };
      setFormData(formDatass);
    }
  };

  const {
    directreferal,
    bosticbalancedPercentage,
    dueBalancedPercentage,
    companyAmount,
    minimumWithdrawBos,
    minimumWithdrawDue,
    maxReachLimit,
    bosticValue,
    dueValue,
    gracePeriod,
  } = formData;


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();

    // if (accounts != config.adminaddress) {

    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }

    if (Adirectreferal == "") {
      toast.error("Please Enter Direct Referral", toasterOption);
      return false;
    }
    if (AbosticbalancedPercentage == "") {
      toast.error("Please Enter Bostic Tree Balance Percentage", toasterOption);
      return false;
    }
    if (AdueBalancedPercentage == "") {
      toast.error("Please Enter Due Tree Balance Coin", toasterOption);
      return false;
    }
    if (AbosticValue == "") {
      toast.error("Please Enter Bostic Value", toasterOption);
      return false;
    }
    if (AdueValue == "") {
      toast.error("Please Enter Due Value", toasterOption);
      return false;
    }
    if (AgracePeriod == "") {
      toast.error("Please Enter Grace Period", toasterOption);
      return false;
    }
    if (bidClaim === "") {
      toast.error("Please Enter Bidding Reward Claim Period", toasterOption);
      return false;
    }
    if (dueLimit === "") {
      toast.error("Please Enter Due Limit", toasterOption);
      return false;
    }


    if (id == "") {
      var reqData = {
        directreferal: Adirectreferal,
        bosticbalancedPercentage: AbosticbalancedPercentage,
        dueBalancedPercentage: AdueBalancedPercentage,
        companyAmount: AcompanyAmount,
        minimumWithdrawBos: AminimumWithdrawBos,
        minimumWithdrawDue: AminimumWithdrawDue,
        maxReachLimit: AmaxReachLimit,
        bosticValue: AbosticValue,
        dueValue: AdueValue,
        gracePeriod: AgracePeriod,
        bidClaim:bidClaim,
        dueLimit:dueLimit
      };
    } else {
      var reqData = {
        id: id,
        directreferal: Adirectreferal,
        bosticbalancedPercentage: AbosticbalancedPercentage,
        dueBalancedPercentage: AdueBalancedPercentage,
        companyAmount: AcompanyAmount,
        minimumWithdrawBos: AminimumWithdrawBos,
        minimumWithdrawDue: AminimumWithdrawDue,
        maxReachLimit: AmaxReachLimit,
        bosticValue: AbosticValue,
        dueValue: AdueValue,
        gracePeriod: AgracePeriod,
        bidClaim:bidClaim,
        dueLimit:dueLimit
      };
    }


    const { status, message, error, result } = await updatesettings(reqData);

    if (status) {
      toastAlert("success", message, "Admin");
      // history.push("/Admin/dashboard");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else if (error) {
      setValidateError(error);
    }
  };

  const getadmindata = async () => {
    var result = await getsettingsData();

    if (result) {
      setdirectreferal(result.result[0].directreferal);
      setbosticbalancedPercentage(result.result[0].bosticbalancedPercentage);
      setdueBalancedPercentage(result.result[0].dueBalancedPercentage);
      setcompanyAmount(result.result[0].companyAmount);
      setminimumWithdrawBos(result.result[0].minimumWithdrawBos);
      setminimumWithdrawDue(result.result[0].minimumWithdrawDue);
      setmaxReachLimit(result.result[0].maxReachLimit);
      setbosticValue(result.result[0].bosticValue);
      setdueValue(result.result[0].dueValue);
      setgracePeriod(result.result[0].gracePeriod);
      setbidClaim(result.result[0].bidClaim)
      setdueLimit(result.result[0].dueLimit)
      setid(result.result[0]._id);
    }
  };

  useEffect(() => {
    //logout(history)
    getadmindata();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              onSubmit={handleFormSubmit}
              noValidate
            >
              <CardHeader color="primary">
                <h4
                  className={classes.cardTitleWhite}
                  style={{ marginBottom: "5px" }}
                >
                  Update Settings
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      // type="number"
                      labelText="Direct Referral (%) "
                      onChange={onChange}
                      id="directreferal"
                      name="directreferal"
                      value={Adirectreferal}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      labelText="Bostic Tree Balance (%)"
                      onChange={onChange}
                      id="bosticbalancedPercentage"
                      name="bosticbalancedPercentage"
                      value={AbosticbalancedPercentage}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      labelText="Due Tree Balance Coin"
                      onChange={onChange}
                      id="dueBalancedPercentage"
                      name="dueBalancedPercentage"
                      value={AdueBalancedPercentage}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      labelText="Bostic Value ($)"
                      onChange={onChange}
                      id="bosticValue"
                      name="bosticValue"
                      value={AbosticValue}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      labelText="Due Value ($)"
                      onChange={onChange}
                      id="dueValue"
                      name="dueValue"
                      value={AdueValue}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      labelText="Grace Period (Hours)"
                      onChange={onChange}
                      id="gracePeriod"
                      name="gracePeriod"
                      value={AgracePeriod}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      labelText="Bidding Reward Claim (Days)"
                      onChange={onChange}
                      id="bidClaim"
                      name="bidClaim"
                      value={bidClaim}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>



                  {/* <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      labelText="Direct Due Tree Amount"
                      onChange={onChange}
                      id="duetreAmt"
                      name="duetreAmt"
                      value={AcompanyAmount}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem> */}
{/* 
                  <GridItem xs={12} sm={12} md={6} lg={4}>
                    <CustomInput
                      labelText="Due Limit"
                      onChange={onChange}
                      id="dueLimit"
                      name="dueLimit"
                      value={dueLimit}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem> */}

                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
