// import lib
import isEmpty from '../../lib/isEmpty';


const validation = value => {
    let errors = {},
        emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    var paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if (isEmpty(value.email)) {
        errors.email = "Required"
    } else if (!(emailRegex.test(value.email))) {
        errors.email = "Invalid email"
    }

    if (isEmpty(value.password)) {
        errors.password = "Required"
    }
    // } if (isEmpty(value.Newpassword)) {
    //     errors.Newpassword = "Required"
    // } else if (!(paswd.test(value.Newpassword))) {
    //     errors.Newpassword = "7 to 15 characters which contain at least one numeric digit and a special character"
    // } if (isEmpty(value.confirmPassword)) {
    //     errors.confirmPassword = "Required"
    // }

    return errors;
}

export default validation;