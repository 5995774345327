import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import * as moment from "moment";
import Alert from "@material-ui/lab/Alert";
import { Link, useHistory } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import config from "../../actions/config";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FileSaver from "file-saver";
import Papa from "papaparse";
import PhoneInput from "react-phone-input-2";
import "../../style/PhoneInput.css";

import {
  UpdateUsers,
  getExitsUsers,
  getExitsRef,
  getPackagesRestric,
  getPackages,
} from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";
import { Web } from "@material-ui/icons";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
  input: {
    display: "none",
  },
};

// toaster config
toast.configure();

const initialFormValue = {
  userAdd: "",
  refAdd: "",
  planID: "",
  name: "",
  email: "",
  phone: "",
  side: "",
};

let fileData = {
  address: "",
  refaddress: "",
  plan: "",
  side: "",
  name: "",
  email: "",
  phone: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

const initialFormValue1 = {
  email: "",
  password: "",
  twoFACode: "",
  phoneNo: "",
  otp: "",
  reCaptcha: "",
  roleType: 1,
  phoneCode: "",
  showPassword: false,
  isTerms: false,
};

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialFormValue);
  const [formValue, setFormValue] = useState(initialFormValue1);
  const [validateError, setValidateError] = useState({});

  const [file, setFile] = useState("");
  const [bulkData, setBulkData] = useState([]);
  const [uploadData, setUploadData] = useState(fileData);
  const [rejeU, setRejeU] = useState([]);
  const [rejeI, setRejeI] = useState([]);
  const [rejP, setRejP] = useState([]);
  const [invUA, setInvUA] = useState([]);
  const [SideMiss, setSideMiss] = useState([]);
  const [EmpytPlan, setEmptyPlan] = useState([]);
  const [EmpName, setEmpName] = useState([]);
  const [EmpEmail, setEmpEmail] = useState([]);
  const [EmpPhone, setEmpPhone] = useState([]);
  const [ie, seti] = useState(0);
  const [ide, setIde] = useState("");
  const [PlanName, setPlanName] = useState({
    plan1: 0,
    status1: false,
    plan2: 0,
    status2: false,
    plan3: 0,
    status3: false,
    plan4: 0,
    status4: false,
    plan5: 0,
    status5: false,
    plan6: 0,
    status6: false,
    plan7: 0,
    status7: false,
  });
  const { phoneNo, phoneCode } = formValue;

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } };
    }
    setFormValue(formData);
  };
  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formDatass = { ...formData, ...{ [name]: value } };
    setFormData(formDatass);
  };

  const { userAdd, refAdd, planID, name, email, phone, side } = formData;

  useEffect(() => {
    getPackageNames();
  }, []);

  const getPackageNames = async () => {
    const data = await getPackages();
    setPlanName({
      ...PlanName,
      plan1: data && data.result && data.result.result[0].planAmount,
      status1: data && data.result && data.result.result[0].status,
      plan2: data && data.result && data.result.result[1].planAmount,
      status2: data && data.result && data.result.result[1].status,
      plan3: data && data.result && data.result.result[2].planAmount,
      status3: data && data.result && data.result.result[2].status,
      plan4: data && data.result && data.result.result[3].planAmount,
      status4: data && data.result && data.result.result[3].status,
      plan5: data && data.result && data.result.result[4].planAmount,
      status5: data && data.result && data.result.result[4].status,
      plan6: data && data.result && data.result.result[5].planAmount,
      status6: data && data.result && data.result.result[5].status,
      plan7: data && data.result && data.result.result[6].planAmount,
      status7: data && data.result && data.result.result[6].status,
    });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();

    // if (accounts != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }
    const payload = {
      address: userAdd,
    };
    const payload1 = {
      address: refAdd,
    };

    if (!userAdd) {
      toast.error("Please enter User Address", toasterOption);
      return false;
    }
    let result = Web3.utils.isAddress(userAdd);
    if (!result) {
      toast.error("Invalid User Address", toasterOption);
      // let res ={
      //   isaddress: result,
      //   addrss: userAdd
      // }
      return false;
    }
    const ExitUser = await getExitsUsers(payload);
    if (ExitUser.result.status) {
      toast.error("User Address already registerd", toasterOption);
      return false;
    }

    if (!refAdd) {
      toast.error("Please enter Referral Address", toasterOption);
      return false;
    }
    const ExitRef = await getExitsRef(payload1);
    if (!ExitRef.result.status) {
      toast.error("Invalid Referral Address", toasterOption);
      return false;
    }
    if (!planID) {
      toast.error("Please Choose Plan Id", toasterOption);
      return false;
    }
    if (!side) {
      toast.error("Please Choose Side", toasterOption);
      return false;
    }
    if (!name) {
      toast.error("Please Enter Name", toasterOption);
      return false;
    }
    if (!isValidEmail(email)) {
      toastAlert("error", "Please Enter Valid Email", "register");
      return;
    }
    if (!phoneNo) {
      toast.error("Please Enter Phone", toasterOption);
      return false;
    }

    let reqData = {
      address: userAdd,
      refaddress: refAdd,
      plan: planID,
      side: side,
      name: name,
      email: email,
      phone: phoneCode + " " + phoneNo,
      isAdminAdd: true,
    };
    const data = await UpdateUsers(reqData);

    if (data.loading == true) {
      toastAlert("success", "User Successfully added", "Admin");
      history.push("/bosticadmin/users-add");
    } else {
      toastAlert("error", "Some Problem when User add", "Admin");
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      setFile(e.target.files[0]);
    }
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: "greedy",
      complete: function (res) {
        const newArrayOfObj = res.data.map(
          ({
            "User Address": address,
            "Referral Address": refaddress,
            Plan: plan,
            Name: name,
            Phone: phone,
            Email: email,
            Side: side,
            ...rest
          }) => ({
            address,
            refaddress,
            plan,
            name,
            email,
            phone,
            side,
            ...rest,
          })
        );
        setBulkData(newArrayOfObj);
      },
    });
  };

  const isValidAddress = (addr) => {
    let result = Web3.utils.isAddress(addr);
    return {
      address: addr,
      result: result,
    };
  };

  const BulkSubmit = async () => {
    const rejectUsers = [];
    const invalidRef = [];
    const invalidPln = [];
    const invalidUAddr = [];
    const sideMiss = [];
    const empPlan = [];
    const uName = [];
    const uEmail = [];
    const uPhone = [];

    if (!file) {
      toast.error("Please upload file");
      return false;
    }
    for (let i = 0; i < bulkData.length; i++) {
      bulkData[i].isAdminAdd = true;
      const payload = {
        address: bulkData[i].address,
      };
      const payload1 = {
        address: bulkData[i].refaddress,
      };
      const payload2 = {
        plan: bulkData[i].plan,
      };
      if (bulkData[i].side == "right") {
        bulkData[i].side = "Right";
      }
      if (bulkData[i].side == "left") {
        bulkData[i].side = "Left";
      }
      const data12 = isValidAddress(bulkData[i].address);
      if (data12.result == false) {
        invalidUAddr.push(data12.address);
        continue;
      }
      if (bulkData[i].side == "") {
        sideMiss.push(bulkData[i].address);
        continue;
      }
      if (bulkData[i].plan == "") {
        empPlan.push(bulkData[i].address);
        continue;
      }
      if (bulkData[i].name == "") {
        uName.push(bulkData[i].address);
        continue;
      }
      if (bulkData[i].email == "") {
        uEmail.push(bulkData[i].address);
        continue;
      }
      if (bulkData[i].phone == "") {
        uPhone.push(bulkData[i].address);
        continue;
      }
      const ExitUser = await getExitsUsers(payload);
      if (ExitUser.result?.status) {
        rejectUsers.push(ExitUser.result.result?.address);
      }

      const ExitRef = await getExitsRef(payload1);
      if (ExitRef.result?.status == false) {
        invalidRef.push(ExitRef.result.result);
      }
      const invaliPln = await getPackagesRestric(payload2);
      if (invaliPln.result?.status == false) {
        invalidPln.push(invaliPln.result.result);
        continue;
      }

      const data = await UpdateUsers(bulkData[i]);

      if (data.loading == true) {
        toastAlert("success", "Multiple Users Successfully added", "Admin");
        // window.location.reload()
      }
    }
    setRejeU(rejectUsers);
    setRejeI(invalidRef);
    setRejP(invalidPln);
    setInvUA(invalidUAddr);
    setSideMiss(sideMiss);
    setEmptyPlan(empPlan);
    setEmpName(uName);
    setEmpEmail(uEmail);
    setEmpPhone(uPhone);
  };

  const SampleDownload = async () => {

    let sliceSize = 1024;
    let byteCharacters = atob(
      "VXNlciBBZGRyZXNzLFJlZmVycmFsIEFkZHJlc3MsUGxhbixOYW1lLFBob25lLEVtYWlsLFNpZGUKMHgwMDAwMDAwMDAwMDAwMDAsMHgwMDAwMDAwMDAwMDAwMDAwMCwyLFVzZXJuYW1lLCs5MSA5ODc2NTQzMjEwLHVzZXIxQG1haWwuY29tLGxlZnQK"
    );
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.csv" }),
      `SampleFile${moment(Date.now()).format("YYYYMMDDhhmmss")}.csv`
    );
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4
                  className={classes.cardTitleWhite}
                  style={{ marginBottom: "5px" }}
                >
                  Add Users
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      style={{ width: "100%", marginTop: "20px" }}
                      id="outlined-basic"
                      label="User Address"
                      variant="outlined"
                      name="userAdd"
                      onChange={onChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      style={{ width: "100%", marginTop: "20px" }}
                      id="outlined-basic"
                      label="Referral Address"
                      name="refAdd"
                      variant="outlined"
                      onChange={onChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Plan
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        //   value={planID}
                        onChange={onChange}
                        name="planID"
                        label="Plan"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {PlanName.status1 && (
                          <MenuItem value={1}>$ {PlanName.plan1}</MenuItem>
                        )}
                        {PlanName.status2 && (
                          <MenuItem value={2}>$ {PlanName.plan2}</MenuItem>
                        )}
                        {PlanName.status3 && (
                          <MenuItem value={3}>$ {PlanName.plan3}</MenuItem>
                        )}
                        {PlanName.status4 && (
                          <MenuItem value={4}>$ {PlanName.plan4}</MenuItem>
                        )}
                        {PlanName.status5 && (
                          <MenuItem value={5}>$ {PlanName.plan5}</MenuItem>
                        )}
                        {PlanName.status6 && (
                          <MenuItem value={6}>$ {PlanName.plan6}</MenuItem>
                        )}
                        {PlanName.status7 && (
                          <MenuItem value={7}>$ {PlanName.plan7}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      style={{ width: "100%", marginTop: "20px" }}
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      name="name"
                      onChange={onChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <PhoneInput
                      style={{ width: "100%", marginTop: "20px" }}
                      country={"in"}
                      className="primary_inp"
                      placeholder="Enter phone number"
                      value={phoneCode + phoneNo}
                      onChange={handlePhoneNumber}
                    />
                    {/* <TextField
                      style={{ width: "100%", marginTop: "20px" }}
                      id="outlined-basic"
                      label="Phone Number"
                      variant="outlined"
                      name="phone"
                      onChange={onChange}
                    /> */}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      style={{ width: "100%", marginTop: "20px" }}
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      name="email"
                      onChange={onChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      component="fieldset"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <FormLabel component="legend">
                        Select your Position
                      </FormLabel>
                      <RadioGroup
                        aria-label="Side"
                        name="side"
                        style={{
                          flexWrap: "inherit",
                          display: "-webkit-inline-box",
                        }}
                        // value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value="Left"
                          control={<Radio />}
                          label="Left"
                          style={{ width: "25%", float: "left" }}
                        />
                        <FormControlLabel
                          value="Right"
                          control={<Radio />}
                          label="Right"
                          style={{ width: "50%", float: "right" }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <Button
                  color="primary"
                  type="submit"
                  style={{ marginBottom: "20px" }}
                >
                  Submit
                </Button>
                {/* <h3>Or</h3> */}
                <hr />
                <h5>Bulk Import</h5>
                <input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  className={classes.input}
                  id="contained-button-file"
                  name="file"
                  //   multiple
                  type="File"
                  onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                  </Button>
                  {file && (
                    <span
                      style={{
                        border: "1px solid #2222",
                        padding: "12px",
                        margin: "8px",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {file.name}
                    </span>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<Icon>send</Icon>}
                    onClick={BulkSubmit}
                  >
                    Submit
                  </Button>
                </label>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  className={classes.button}
                  endIcon={<Icon>download</Icon>}
                  onClick={SampleDownload}
                >
                  Download Sample File
                </Button>
                {rejeU.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>Address {ele} already registerd</div>
                  </Alert>
                ))}
                {rejeI.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>Address {ele} Invalid referral</div>
                  </Alert>
                ))}
                {rejP.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>{ele} Invalid plan</div>
                  </Alert>
                ))}
                {invUA.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>{ele} Invalid user address</div>
                  </Alert>
                ))}
                {SideMiss.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>{ele} Missing side</div>
                  </Alert>
                ))}
                {EmpytPlan.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>{ele} Missing plan</div>
                  </Alert>
                ))}
                {EmpName.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>{ele} Missing Name</div>
                  </Alert>
                ))}
                {EmpEmail.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>{ele} Missing Email</div>
                  </Alert>
                ))}
                {EmpPhone.map((ele, i) => (
                  <Alert severity="error" key={i}>
                    <div>{ele} Missing Phone Number</div>
                  </Alert>
                ))}
              </CardBody>
              <CardFooter></CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
