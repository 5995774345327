import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'

// import action 
// import { logout } from '../../actions/users';

// import lib
import isLogin from '../../lib/isLogin';

const ConditionRoute = ({ component: Component, layout: Layout, type, store, ...rest }) => {
    const history = useHistory();

    // redux-state
    const authData = useSelector(state => state.auth)
    return (
        <Route
            {...rest}
            render={props => {
                if (type == 'auth' && isLogin() == true) {
                    return  <Redirect to="/dashboard" /> 
                } else if (type == 'private' && isLogin() != true) {
                    return <Redirect to="/login" />
                } else if (authData.role != 'superadmin'  && type != 'public' ) { //
                    if (authData.restriction && authData.restriction.length > 0) {
                        let restrictionData = authData.restriction.find((item => item.path == props.match.path))
                
                        if (!restrictionData && authData.restriction[0].path) {
                            return <Redirect to={authData.restriction[1].path} />
                        } 
                        
                        /* else if (!['/login'].includes(props.match.path) && !restrictionData) {
                            logout(history)
                            return
                            // return <Redirect to="/login" />
                        } */
                    } /* else if (!['/login'].includes(props.match.path) && (!authData.restriction || (authData && authData.restriction.length <= 0))) {
                        // console.log("-----test")
                        logout(history)
                        return
                        // return <Redirect to="/login" />
                    } */

                }

                if (Layout) {
                    return (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )
                }

                return <Component {...props} />
            }}
        />
    )

};

export default ConditionRoute;