import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";

import 'react-phone-input-2/lib/style.css'


import { getadminDetails, updateProfile } from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none"
  }
};

// toaster config
toast.configure();

const initialFormValue = {
  'name': ""
}

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [adminnameset, setAdminName] = useState('')
  const [adminemailset, setAdminEmail] = useState('')


  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAdminName(value)
    setValidateError({})
    let formDatass = { ...formData, ...{ [name]: value } }
    setFormData(formDatass)
  }

  const {
    name
  } = formData

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let reqData = {
      AdminName: adminnameset ? adminnameset : name
    }

    const { status, message, error, result } = await updateProfile(reqData);
    getadmindata()
    if (status) {
      toastAlert('success', message, 'Admin')
      history.push('/Admin/dashboard')
      //window.location=('/Admin/categorylist')
    }
    else if (error) {
      setValidateError(error);
    }


  }
  const getadmindata = async () => {
    var { result } = await getadminDetails();
    if (result) {
      setAdminName(result.name)
      setAdminEmail(result.email)
    }


  }
  useEffect(() => {
    //logout(history)
    getadmindata();
  }, [])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update Users</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Name"
                      onChange={onChange}
                      name="name"
                      value={adminnameset}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                    {
                      validateError.AdminName && <span style={{ color: 'red' }}>{validateError.AdminName}</span>

                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput variant="filled" disabled={true}

                      labelText="Email address"
                      className='classes.disabled'
                      value={adminemailset}
                      name='email'
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Update</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
