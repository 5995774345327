let key = {};
let Envname = "demo";
if (Envname == "demo") {
 // const API_URL = "https://bosticapi.alwin.io"; //DEMO
 const API_URL = "https://api.doublerich.biz"; //Live
  key = {
    baseUrl: `${API_URL}`,
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
  };
}

export default key;
