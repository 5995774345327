import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import Web3 from "web3";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import config from "../../actions/config";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";

import "react-phone-input-2/lib/style.css";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert.js";
import { plandetailupdate, getsingleplandata } from "../../actions/admin";
import { TextField } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  planAmount: "",
  planId: "",
  planName: "",
  cellingAmount: "",
  depositLock: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [Aamount, setamount] = useState("");
  const [Aplanid, setplanid] = useState("");
  const [AplanName, setplanName] = useState("");
  const [AcellingAmount, setcellingAmount] = useState("");
  const [AdepositLock, setdepositLock] = useState("");
  const [AMaxLiDu, setAMaxLiDu] = useState("");

  const { planid } = useParams();

  const onChange = (e) => {
    e.preventDefault();

    if (e.target.name === "planName") {
      const { id, value } = e.target;
      setplanName(value);
      let formData = { ...formValue, ...{ [id]: value } };
      setFormValue(formData);
    } else if (e.target.name === "planId") {
      const { id, value } = e.target;
      setplanid(value);
      let formData = { ...formValue, ...{ [id]: value } };
      setFormValue(formData);
    } else if (e.target.name === "planAmount") {
      const { id, value } = e.target;
      setamount(value);
      let formData = { ...formValue, ...{ [id]: value } };
      setFormValue(formData);
    } else if (e.target.name === "cellingAmount") {
      const { id, value } = e.target;
      setcellingAmount(value);
      let formData = { ...formValue, ...{ [id]: value } };
      setFormValue(formData);
    } else if (e.target.name === "depositLock") {
      const { id, value } = e.target;
      setdepositLock(value);
      let formData = { ...formValue, ...{ [id]: value } };
      setFormValue(formData);
    } else if (e.target.name === "maxLi") {
      const { id, value } = e.target;
      setAMaxLiDu(value);
      let formData = { ...formValue, ...{ [id]: value } };
      setFormValue(formData);
    }
  };

  const {
    planAmount,
    planId,
    planName,
    cellingAmount,
    depositLock,
  } = formValue;

  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();
    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();

    // if (accounts != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }

    if (parseInt(Aamount) > parseInt(AdepositLock)) {
      toast.error("Deposit lock amount must be greater then Plan Amount");
      return false;
    }

    if (parseInt(AdepositLock) > parseInt(AcellingAmount)) {
      toast.error("Deposit lock amount must be lesser then Ceiling Amount");
      return false;
    }
    if (parseInt(AcellingAmount) < parseInt(AMaxLiDu)) {
      toast.error("Due Limit amount must be lesser then Ceiling Amount");
      return false;
    }
    if (AMaxLiDu == "") {
      toast.error("Please enter limit Due amount");
      return false;
    }
    if (AplanName == "") {
      toast.error("Please enter plan name");
      return false;
    }
    if (Aamount == "") {
      toast.error("Please enter plan amount");
      return false;
    }

    let reqData = {
      planAmount: Aamount,
      planId: Aplanid,
      planName: AplanName,
      cellingAmount: AcellingAmount,
      depositLock: AdepositLock,
      id: planid,
      maxlimit: AMaxLiDu,
    };

    let { status, message, error, result } = await plandetailupdate(reqData);

    if (status) {
      toastAlert("success", message, "Admin");
      // history.push("/bosticadmin/plan-details");
      window.location.href = "/bosticadmin/plan-details";
    } else if (error) {
      setValidateError(error);
    }
  };

  const getplan = async () => {
    var { result } = await getsingleplandata(planid);

    setamount(result.planAmount);
    setplanid(result.planId);
    setplanName(result.planName);
    setcellingAmount(result.cellingAmount);
    setdepositLock(result.depositLock);
    setAMaxLiDu(result.maxLimitDue);
    setFormValue(result);
  };

  useEffect(() => {
    getplan();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update Plan</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Plan Amount"
                      onChange={onChange}
                      value={Aamount}
                      id="planAmount"
                      name="planAmount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Deposit Lock"
                      id="depositLock"
                      onChange={onChange}
                      value={AdepositLock}
                      name="depositLock"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Ceiling Amount"
                      id="cellingAmount"
                      onChange={onChange}
                      value={AcellingAmount}
                      name="cellingAmount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Plan Name"
                      onChange={onChange}
                      id="planName"
                      name="planName"
                      value={AplanName}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Max Limit Due"
                      onChange={onChange}
                      value={AMaxLiDu}
                      id="maxLi"
                      name="maxLi"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Update
                </Button>
                <Button
                  color="secondary"
                  type="submit"
                  styles={{ float: "right" }}
                  onClick={() =>
                    (window.location.href = "/bosticadmin/plan-details")
                  }
                >
                  Back
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
