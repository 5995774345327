import React,{useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import config from "../../actions/config";
import { Link } from "react-router-dom";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardFooter from "../../components/Card/CardFooter.js";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CardBody from "../../components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Web3 from 'web3'
import { updateDashInfo,getDashInfo } from "../../actions/admin.js";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};
const useStyles = makeStyles(styles);
const customStyle = makeStyles(customInputStyle);
export default function DashboardInfo() {
  const classes = useStyles();
  const [info,setInfo] = useState("")
  const [ide,setIde] = useState("")
    useEffect(()=>{
        getDashI()
    },[])
    const getDashI =async () => {
        const data = await getDashInfo()
        setIde(data.result._id)
        setInfo(data.result.information)
    }

  const InfoSubmit = async() => {
    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();
    // // setAccount(accounts);
    // if (accounts != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }
    const payload = {
        dashInfo:info,
        id:ide
    }
    const data = await updateDashInfo(payload)
    if(data){
        toast.success("Information Successfully Added",toasterOption)
    }
  }
  return (
    <GridContainer>
      <GridItem xs={2} sm={1} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>User Dashboard Scroll Information</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {/* <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder="Enter Information"
          /> */}
                <TextField
                  id="outlined-basic"
                  label="Enter Information"
                  value={info}
                  variant="outlined"
                  onChange={(e)=>setInfo(e.target.value)}
                  style={{
                    width: "80%",
                    marginTop: "40px",
                    marginBottom: "40px",
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={InfoSubmit}
                  style={{
                    width: "10%",
                    height: "55px",
                    marginTop: "40px",
                    marginBottom: "40px",
                    marginLeft: "10px",
                  }}
                >
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
