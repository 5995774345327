import React, { useEffect, useState, Fragment } from "react";
import "../../style/Loadcss.css";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import ReactLoading from "react-loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Modal from "react-modal";
import {
  getwithdrawrequest,
  rejecterequest,
  rejectePending,
  dclinePending,
  approveAdmin,
  getsettingsData,
} from "../../actions/admin";
import Search from "@material-ui/icons/Search";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Link, useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { toastAlert } from "../../lib/toastAlert.js";
import CardHeader from "../../components/Card/CardHeader.js";
import * as moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import { toast } from "react-toastify";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Web3 from "web3";
// import config from "../../lib/config";
import bosticAbi from "../../ABI/bostic.json";
import DueAbi from "../../ABI/DueAbi.json";
import config from "../../actions/config";

const headCells = [
  { id: "userID", numeric: false, disablePadding: true, label: "userID" },
  { id: "Coin", numeric: false, disablePadding: true, label: "Coin" },
  { id: "tokendate", numeric: true, disablePadding: false, label: "Action" },
];

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 76%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
  const classes = useStyles();
  const customStyles = customStyle();
  const history = useHistory();
  const classesSearch = useToolbarStyles();
  const [data, setData] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userDetails, setUser] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [responsive, setresponsive] = useState(true);
  // const [Account, setAccount] = useState();
  const [settings, setsettings] = useState({});
  const [FormSubmitLoading, Set_FormSubmitLoading] = useState(undefined);
  const [FormrejectLoading, setFormrejectLoading] = useState(undefined);
  const [Sdate, setSDate] = useState();
  const [Scoin, setSCoin] = useState();
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen1, setIsOpen1] = React.useState(false);
  const [RejID, setRejID] = useState();
  const [RejID1, setRejID1] = useState();
  const [RejRes, setRejRes] = useState();
  const [DecRes, setDecRes] = useState();
  const [loadings, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);

  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [shortaccount, setshortAccount] = useState(null);

  useEffect(() => {
    acc();
  }, []);

  async function ConnectWallet() {
    try {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        try {
          const accounts = await web3Instance.eth.requestAccounts();
          console.log("🚀 ~ ConnectWallet ~ accounts:", accounts)

          // if (
          //   accounts[0].toLowerCase() ===
          //   process.env.XACT_ADMIN_WITHDRAWWALLET.toLowerCase()
          // ) {
          setAccount(accounts[0]);
          setshortAccount(halfAddrShow(accounts[0]));

          // } else {
          //   toast.error("Please connect with Withdraw Wallet", {
          //     autoClose: 1500,
          //     closeButton: false,
          //     icon: false,
          //     progress: false,
          //     theme: "colored",
          //   });
          // }
        } catch (error) {
          console.error("Error connecting to wallet:", error);
        }
      } else {
        console.error("MetaMask not found");
      }
    } catch (Err) {}
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 8) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    setAccount(accounts);
  };
  const customStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
    },
  };
  const editR = async (id, address, amount, coin, index) => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();

    console.log(
      "🚀 ~ editR ~ id, address, amount, coin, index:",
      id,
      address,
      amount,
      coin,
      index
    );

    // if (accounts != config.adminaddress) {
    //   toast.error(
    //     `Please Connect ${halfAddrShow(config.adminaddress)}`,
    //     toasterOption
    //   );
    //   return false;
    // }
    if (!account) {
      setLoading(false);
      toastAlert("error", "Please connect with MetaMask", "Admin");

      return 0;
    }

    try {
      if (coin == 1) {
        Set_FormSubmitLoading(index);
        setLoading(true);
        const web3 = new Web3(window.ethereum);

        const BEPContract = new web3.eth.Contract(
          DueAbi,
          config.Duecoin_contract
        );
        var tokamount = amount;
        //  * settings.dueValue;
        var tokamount1 = tokamount * 1e18;

        var amt = tokamount1.toString();
        amt = convert(amt);

        let  res = await BEPContract.methods.transfer(address, amt).send({
          from: account,
        }).on('transactionHash',async function(hash){
          let reqData = {
            id: id,
            transactionHash: hash,
          };
  
  
          const { status, message, error, result } = await approveAdmin(reqData);
  
          if (status) {
            toastAlert("success", message, "Admin");
            setLoading(false);
            window.location.reload();
            Set_FormSubmitLoading("done");
          } else if (error) {
            console.log(error);
            setLoading(false);
            Set_FormSubmitLoading("done");
          }
        })



     


      } else if (coin == 0) {
        Set_FormSubmitLoading(index);
        setLoading(true);
        const web3 = new Web3(window.ethereum);

        const BEPContract = new web3.eth.Contract(
          bosticAbi,
          config.bostic_contract
        );
        var tokamount = (1 / settings.bosticValue) * amount;
        // * settings.bosticValue;
        var tokamount1 = tokamount * 1e18;
        var amt = tokamount1.toString();
        amt = convert(amt);

        var res = await BEPContract.methods.transfer(address, amt).send({
          from: account,
        }).on('transactionHash',async function(hash) {
          let reqData = {
            id: id,
            transactionHash:hash,
          };
          const { status, message, error, result } = await approveAdmin(reqData);
  
          if (status) {
            toastAlert("success", message, "Admin");
            window.location.reload();
            setLoading(false);
            Set_FormSubmitLoading("done");
          } else if (error) {
            console.log(error);
            setLoading(false);
            Set_FormSubmitLoading("done");
          }

        })

    
      } else if (coin == 2) {
        Set_FormSubmitLoading(index);
        setLoading(true);
        let web3Instance = new Web3(window.ethereum);

        const BEPContract = new web3Instance.eth.Contract(
          bosticAbi,
          config.bostic_contract
        );
        var tokamount = amount;
        // * settings.bosticValue;
        var tokamount1 = tokamount * 1e18;
        console.log("🚀 ~ editR ~ tokamount1:", tokamount1);
        var amt = tokamount1.toString();
        amt = convert(amt);
        console.log("🚀 ~ editR ~ amt:", amt);

        var res = await BEPContract.methods.transfer(address, amt).send({
          from: account,
        }).on('transactionHash',async function(hash) {
          console.log("🚀 ~ res ~ hash:", hash)
          let reqData = {
            id: id,
            transactionHash:hash,
          };
          const { status, message, error, result } = await approveAdmin(reqData);
          console.log("🚀 ~ res ~ result:", result)
          console.log("🚀 ~ res ~ error:", error)
          console.log("🚀 ~ res ~ message:", message)
          console.log("🚀 ~ res ~ status:", status)
  
          if (status) {
            toastAlert("success", message, "Admin");
            window.location.reload();
            setLoading(false);
            Set_FormSubmitLoading("done");
          } else if (error) {
            console.log(error);
            setLoading(false);
            Set_FormSubmitLoading("done");
          }
        })

        
      }
    } catch (err) {
      console.log("🚀 ~ editR ~ err:", err)
      setLoading(false);
      toast.error("Transaction rejected by user", toasterOption);
      Set_FormSubmitLoading("done");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name == "date") {
      setSDate(e.target.value);
    } else {
      setSCoin(e.target.value);
    }
  };

  useEffect(() => {
    getAll();
  }, [Scoin, Sdate]);
  const getAll = async (search = {}) => {
    const payload = {
      data: Sdate,
      coin: Scoin,
    };
    var test = await getwithdrawrequest(payload);
    var sett = await getsettingsData();
    setsettings(sett.result[0]);

    if (test && test.result.Data) {
      test.result.Data.map((ele, i) => {
        ele.SSno = i + 1;
      });
      setUser(test.result.Data);
    }
  };

  const handleDelete = async (iid, index) => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    if (accounts != config.adminaddress) {
      toast.error(
        `Please Connect ${halfAddrShow(config.adminaddress)}`,
        toasterOption
      );
      return false;
    }
    try {
      setFormrejectLoading(index);
      var data = {
        id: iid,
        // resion:RejRes
        // status: statusdata,
      };

      const res = await rejecterequest(data);

      window.location.reload();
      window.location.href = "/bosticadmin/rejectList";
      if (res) {
        toastAlert("success", res.userValue.data.message, "currency");
        getAll();
        window.location.href = "/bosticadmin/rejectList";
        setFormrejectLoading("done");
      }
    } catch (err) {}
  };

  const handleReject = async (iid) => {
    // if (Account != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }
    if (RejRes == "" || RejRes == undefined) {
      toast.error("Enter Reject Reason", toasterOption);
      return false;
    }
    try {
      let payload = {
        id: iid,
        resion: RejRes,
      };
      const res = await rejectePending(payload);
      if (res) {
        toast.success("Reject Success", toasterOption);
        getAll();
        window.location.href = "/bosticadmin/pending-list";
        setFormrejectLoading("done");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDecline = async (iid) => {
    // if (Account != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }
    if (DecRes == "" || DecRes == undefined) {
      toast.error("Enter Decline Reason", toasterOption);
      return false;
    }
    try {
      let payload = {
        id: iid,
        resion: DecRes,
      };
      const res = await dclinePending(payload);
      if (res) {
        toast.success("Reject Success", toasterOption);
        getAll();
        window.location.href = "/bosticadmin/pending-list";
        setFormrejectLoading("done");
      }
    } catch (err) {
      console.log(err);
    }
  };

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  async function openModal(rejeId) {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    // if (accounts != config.adminaddress) {
    //   toast.error(
    //     `Please Connect ${halfAddrShow(config.adminaddress)}`,
    //     toasterOption
    //   );
    //   return false;
    // }
    setIsOpen(true);
    setRejID(rejeId);
  }

  async function openModal1(rejeId) {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    // if (accounts != config.adminaddress) {
    //   toast.error(
    //     `Please Connect ${halfAddrShow(config.adminaddress)}`,
    //     toasterOption
    //   );
    //   return false;
    // }
    setIsOpen1(true);
    setRejID1(rejeId);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModal1() {
    setIsOpen1(false);
  }

  const rows1 = userDetails;

  const columns = [
    // { field: 'planId', headerName: 'ID', width: 90 },
    {
      field: "SSno",
      headerName: "S.No.",
      width: 80,
      filterable: false,
      // renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
    },
    {
      field: "userID",
      headerName: "User ID",
      width: 70,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) => `${params.row.userID}`,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
      // type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) => `${params.row.userName}`,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) => `${params.row.amount}`,
    },
    {
      field: "coin",
      headerName: "Coin",
      width: 150,
      // type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (record) => (
        <>
          {" "}
          {record.row.coin == 0
            ? "Bostic"
            : record.row.coin == 1
            ? "Due"
            : "Bostic (Bidding Reward)"}
        </>
      ),
    },
    {
      field: "ExitReq",
      headerName: "Status",
      width: 130,
      // type: 'number',
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (record) => (
        <div>
          <>{record.row.ExitReq ? "Exit Request" : "Request"}</>
        </div>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Date",
      width: 270,
      type: "date",
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (record) => (
        <>{moment(record.row.updatedAt).format("DD MMM YYYY hh:mm:ss A")}</>
      ),
    },
    // {
    //   field: "index",
    //   headerName: "Index",
    //   width: 270,
    //   type: 'date',
    //   headerAlign: 'left',
    //   align:'left',
    //   editable: false,
    //   valueGetter:(index) => index.api.getRowIndex(index.row._id),
    // },
    {
      field: "action",
      headerName: "Actions",
      width: 260,
      // type: 'date',
      headerAlign: "left",
      align: "left",
      editable: false,
      disableColumnMenu: true,
      renderCell: (record, index) => {
        const checkin = record.row.status;
        // console.log(checkin,index, "checkineeed");
        if (checkin == false) {
          var lockVal = "fa fa-lock";
        } else {
          var lockVal = "fa fa-unlock";
        }

        return (
          <Fragment>
            {record.row.status == 0 ? (
              <div>
                <button
                  data-toggle="modal"
                  data-target="#update-template-modal"
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    editR(
                      record.row._id,
                      record.row.user.address,
                      record.row.amount,
                      record.row.coin,
                      index
                    )
                  }
                  style={{ marginRight: "5px" }}
                  // disabled={FormSubmitLoading == index}
                >
                  {/* {loadings == true && (
                    <i
                      class="fa fa-spinner fa-spin"
                      aria-hidden="true"
                      id="circle"
                    ></i>
                  )}{" "} */}
                  Approve
                </button>
                <button
                  className="btn btn-warning btn-sm mr-1"
                  // onClick={() => handleDelete(record.row._id, index)}
                  onClick={() => openModal(record.row._id)}
                  // disabled={FormrejectLoading == index}
                >
                  {/* {FormrejectLoading == index && (
                    <i
                      class="fa fa-spinner fa-spin"
                      aria-hidden="true"
                      id="circle"
                    ></i>
                  )} */}
                  Reject
                </button>
                <button
                  className="btn btn-danger btn-sm mr-1"
                  // onClick={() => handleDelete(record.row._id, index)}
                  // onClick={()=>openModal(record.row._id)}
                  onClick={() => openModal1(record.row._id)}
                  // disabled={FormrejectLoading == index}
                >
                  {/* {FormrejectLoading == index && (
                    <i
                      class="fa fa-spinner fa-spin"
                      aria-hidden="true"
                      id="circle"
                    ></i>
                  )} */}
                  Decline
                </button>
              </div>
            ) : (
              "Rejected"
            )}
          </Fragment>
        );
      },
    },
  ];

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              color="primary"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h4
                className={classes.cardTitleWhite}
                style={{ marginBottom: "5px" }}
              >
                Withdraw Request
              </h4>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                onClick={() => ConnectWallet()}
                style={{ marginLeft: "5px" }} // Adjusted margin to add some spacing
              >
                {shortaccount == null ? "Connect to Wallet" : shortaccount}
              </button>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ height: 900, width: "100%" }}>
                    <DataGrid
                      rows={rows1}
                      columns={columns}
                      // pageSize={15}
                      getRowId={(row) => row._id}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[15, 20, 30]}
                      pagination
                      // rowsPerPageOptions={[5]}
                      checkboxSelection={false}
                      disableSelectionOnClick
                      disableColumnSelector
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles1}
        contentLabel="Example Modal"
      >
        <h4 className={classes.cardTitleWhite} style={{ marginBottom: "25px" }}>
          Reject Reason Submission
        </h4>
        <TextField
          id="outlined-basic"
          label="Reject Reason"
          variant="outlined"
          onChange={(e) => setRejRes(e.target.value)}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleReject(RejID)}
        >
          Submit
        </Button>
      </Modal>

      {/* Declain Resion Submit */}
      <Modal
        isOpen={modalIsOpen1}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal1}
        style={customStyles1}
        contentLabel="Example Modal"
      >
        <h4 className={classes.cardTitleWhite} style={{ marginBottom: "25px" }}>
          Decline Reason Submission
        </h4>
        <TextField
          id="outlined-basic"
          label="Decline Reason"
          variant="outlined"
          onChange={(e) => setDecRes(e.target.value)}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDecline(RejID1)}
        >
          Submit
        </Button>
      </Modal>
      {loadings && (
        <div className="loader_section_">
          <ReactLoading
            type={"bubbles"}
            color={"#000000"}
            className="loading"
          />
        </div>
      )}
    </>
  );
}
