// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon,
} from "@material-ui/icons";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ListIcon from "@material-ui/icons/List";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import AssessmentIcon from "@material-ui/icons/Assessment";

const dashboardRoutes = [
  {
    name:"Login",
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  // {
  //   id: "security",
  //   path: "/securityType",
  //   isSideMenu: false,
  //   isEdit: false,
  //   isRestricted: 'common'
  // },
  {
    name:"Edit Profile",
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  /*Admin  */
  {
    path: "/list",
    id: "admin",
    name: "Admin Management",
    icon: List,
    isSideMenu: true,
    isEdit: false,
    // isRestricted: 'multi',
    child: [
      {
        id: "add",
        path: "/add",
      },
      {
        id: "edit",
        path: "/edit/:userId",
      },
    ],
  },

  /* Dashboard */
  {
    path: "#",
    id: "dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Dashboard Lists",
        path: "/dashboard",
        icon: DashboardIcon,
      },
      // {
      //   name: "User Dashboard Info",
      //   path: "/userdashboard-info",
      //   icon: DashboardIcon,
      // },
    ],
  },

  {
    path: "#",
    id: "Settings",
    name: "Settings",
    icon: SettingsApplicationsIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Settings",
        path: "/setting",
        icon: Settings,
      },
    ],
  },

  {
    path: "#",
    id: "Plan",
    name: "Plan",
    icon: ListIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Plan Details",
        path: "/plan-details",
        icon: ListIcon,
      },
    ],
  },
  // {
  //   name: "Plan Update",
  //   id: "Plan",
  //   path: "/plan-details/:planid",
  //   isSideMenu: false,
  //   isRestric:true,
  //   isEdit: false,
  //   isRestricted: 'Editable'
  // },


  {
    path: "#",
    id: "biddings",
    name: "Biddings",
    icon: AssessmentIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Bidding Percentage",
        path: "/bidding-percentage",
        icon: AssessmentIcon,
      },
      {
        name: "Bidding History",
        path: "/bidding-history",
        icon: AssessmentIcon,
      },
    ],
  },

  {
    path: "#",
    id: "withdraw",
    name: "Withdraw",
    icon: MonetizationOnIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Withdraw",
        path: "/withdrawrequest",
        icon: MonetizationOnIcon,
      },

      {
        name: "Pending List",
        path: "/pending-list",
        icon: MonetizationOnIcon,
      },
      {
        name: "Decline List",
        path: "/rejectList",
        icon: MonetizationOnIcon,
      },
      {
        name: "Withdraw History",
        path: "/withdrawhistory",
        icon: MonetizationOnIcon,
      },
    ],
  },
  {
    path: "#",
    id: "cms",
    name: "CMS",
    icon: BorderColorIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Scroll Information",
        path: "/scroll-info",
        icon: BorderColorIcon,
      },
      {
        name: "Popup Information",
        path: "/popup-info",
        icon: BorderColorIcon,
      },
    ],
  },

  {
    path: "#",
    id: "users",
    name: "Users",
    icon: AccountBoxIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Users List",
        path: "/users",
        icon: AccountBoxIcon,
      },
      {
        name: "Profile Details",
        path: "/profile-detaials",
        icon: AccountBoxIcon,
      },
      {
        name: "Add Users",
        path: "/add-users",
        icon: AccountBoxIcon,
      },
      {
        name: "Admin Added Users",
        path: "/admin-added-users",
        icon: AccountBoxIcon,
      },
    ],
  },
  {
    path: "#",
    id: "chnagepass",
    name: "Change Password",
    icon: LockOpenIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Change Password",
        path: "/change-password",
        icon: LockOpenIcon,
      },
    ],
  },
];

export default dashboardRoutes;
