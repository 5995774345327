import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import jwt_decode from "jwt-decode";
// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import Web3 from "web3";
import config from "../../actions/config";
import { toast } from "react-toastify";

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

// import action
import { getAllAdmin, deleteAdmin } from "./../../actions/admin";
import { toastAlert } from "../../lib/toastAlert";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};

toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const useStyles = makeStyles(styles);



const CoinWithdrawRequest = (props) => {
  const classes = useStyles();

  // state
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(15);

  // useEffect(()=>{
  //   window.location.reload()
  // },[])

  var token = localStorage.getItem("admin_token");
  if(token) {
    token = token.replace("Bearer ", "");
    var decoded = jwt_decode(token);
      console.log(decoded,'dddddddddd');
  }
  

  const rows = data;

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 280,
      // type: 'date',
      headerAlign: "left",
      align: "left",
      editable: false,
      // valueGetter:(params)=>`${moment(params.row.date).format('MMMM Do YYYY, hh:mm:ss a')}`
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
      // type: 'date',
      headerAlign: "left",
      align: "left",
      editable: false,
      // valueGetter:(params)=>`${moment(params.row.date).format('MMMM Do YYYY, hh:mm:ss a')}`
    },
    {
      field: "role",
      headerName: "Actions",
      width: 280,
      // type: 'date',
      headerAlign: "left",
      align: "left",
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Fragment>
            { decoded?.role  == "superadmin" ? (<>
              <Link to={"/eidt/" + params.row._id}>
              <EditIcon style={{ color: "#109ebf" }} />
            </Link>
            <Link onClick={() => handleDelete(params.row._id)}>
              <DeleteIcon style={{ color: "#109ebf" }} />
            </Link>
            </>  ):"-"}
            
          
          </Fragment>
        );
      },
    },
  ];

  // function

  useEffect(() => {
    fetchAllAdmin();
  }, []);

  const fetchAllAdmin = async () => {
    const data = await getAllAdmin();
    setData(data.result);
  };

  const handleDelete = async (id) => {
    try {
      // const web3 = new Web3(window.ethereum);
      // let accounts = await web3.eth.requestAccounts();
  
      // if (accounts != config.adminaddress) {
      //   toast.error("Connect your valid account", toasterOption);
      //   return false;
      // }
      if (window.confirm("Are You Sure Delete?")) {
        const data = await deleteAdmin(id);
        // if (status == "success") {
          toastAlert("success", "User deleted success", "deleteAdmin");
          fetchAllAdmin();
        // }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Admin List</h4>
              <Link to={"/add"} className={classes.btnPrimary}>
                Add Admin
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            {/* <div style={{ height: 600, width: '100%' }}>
                            {
                                data.length > 0   && 
                            
                            <DataGrid pageSize={10} pagination rows={ data } columns={columns} />
                                            }
                        </div> */}
            <div style={{ height: 900, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                // pageSize={15}
                getRowId={(row) => row._id}
                // rowsPerPageOptions={[5]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[15, 20, 30]}
                pagination
                checkboxSelection={false}
                disableSelectionOnClick
                disableColumnSelector
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
};

export default CoinWithdrawRequest;
