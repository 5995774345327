import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import config from "../../actions/config";
import config1 from "../../lib/config";
import { Link } from "react-router-dom";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardFooter from "../../components/Card/CardFooter.js";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CardBody from "../../components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { updateDashPopInfo, getDashPopInfo } from "../../actions/admin.js";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Web3 from "web3";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};
const useStyles = makeStyles(styles);
const customStyle = makeStyles(customInputStyle);
var imagesinit = {
  image: "",
};
export default function DashboardPopupInfo() {
  const classes = useStyles();
  const [info, setInfo] = useState("");
  const [ide, setIde] = useState("");
  const [dashboardimage, setdashboardimage] = useState();
  const [image, setImage] = useState();
  const [title, setTitle] = useState("");
  const [ffile, setFFile] = useState("");
  const [Account, setAccount] = useState();
  const [state, setState] = React.useState({
    checkedB: false,
  });

  useEffect(() => {
    getDashI();
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    console.log(state,'statestate');
  };

  const getDashI = async () => {
    const data = await getDashPopInfo();
    console.log(data, "data000000000000");
    setIde(data.result._id);
    setInfo(data.result.information);
    setTitle(data.result.title);
    setImage(data.result.image);
    setState({ ...state, checkedB:data.result.popup });
  };
  const fileHandle = (e) => {
    // let formdata = {...dashboardimage,...{"image":e.target.files[0]}}
    setdashboardimage(e.target.files[0]);
    setImage(e.target.files[0].name);
    console.log(dashboardimage, "dashboardimage");
    setFFile(URL.createObjectURL(e.target.files[0]));
  };

  const InfoSubmit = async (e) => {
    e.preventDefault();
    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();
    // setAccount(accounts);
    // if (accounts != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }
    const payload = {
      dashInfo: info,
      // title:title,
      popup:state.checkedB,
      image: dashboardimage,
      id: ide,
    };
    console.log(payload, "Payloadddd");
    const data = await updateDashPopInfo(payload);
    console.log(data, "POst");
    if (data) {
      toast.success("Information Successfully Added", toasterOption);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={2} sm={1} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              User Dashboard Popup Information
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.checkedB}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={`Popup ${state.checkedB ? "Enabled" : "Disabled"}`}
                /><br/>
                <Button
                  style={{
                    width: "20%",
                    marginTop: "20px",
                  }}
                  variant="contained"
                  component="label"
                >
                  Upload File
                  <input
                    type="file"
                    id="image"
                    name="avimageatar"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={fileHandle}
                    hidden
                  />
                </Button>
                <span
                  style={{
                    padding: "5px",
                    border: "1px solid #4444",
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px"
                  }}
                >
                  {" "}
                  {image && (
                    <img
                      style={{ width: "34px", height: "34px" }}
                      src={`${config1.baseUrl}/images/${image}`}
                      className="img-fluid"
                    />
                  )}
                  {ffile && (
                    <img
                      style={{
                        width: "34px",
                        height: "34px",
                        marginLeft: "-35px",
                      }}
                      src={ffile}
                      // className="img-fluid my-2"
                    />
                  )}
                  {/* {image && image} */}
                </span>
                <small className="d-block">Note: Jpeg, Png, Jpg files are only allowed</small>
                {/* <TextField
                  id="outlined-basic"
                  label="Enter Title"
                  value={title}
                  variant="outlined"
                  onChange={(e) => setTitle(e.target.value)}
                  style={{
                    width: "100%",
                    marginTop: "30px",
                    // marginBottom: "40px",
                  }}
                /> */}
                <TextField
                  id="outlined-basic"
                  label="Enter Information"
                  value={info}
                  variant="outlined"
                  onChange={(e) => setInfo(e.target.value)}
                  style={{
                    width: "100%",
                    marginTop: "30px",
                    // marginBottom: "40px",
                  }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={InfoSubmit}
                  style={{
                    width: "10%",
                    height: "55px",
                    marginTop: "20px",
                    marginBottom: "40px",
                    marginLeft: "0px",
                  }}
                >
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
