import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";

// import components
import Admin from "./components/layouts/Admin";
import ConditionRoute from "./components/Route/ConditionRoute";

// import pages
import LoginPage from "./pages/LoginPage/Login";

import Dashboard from "./pages/Dashboard/Dashboard";

import store from "./store";

import "./assets/css/material-dashboard-react.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoginHistory from "../deepliquidity_exchange_server/modals/loginHistory";

import globalStyle from "./style/global.style";

// import action
import { decodeJwt } from "./actions/jsonWebToken";
import EditProfile from "./pages/AdminProfile/EditProfile";
import Settings from "./pages/settings/Settings";
import Planupdate from "./pages/plan/planupdate";
import Planedit from "./pages/plan/Planedit";
import withdrawDetail from "./pages/withdraw/withdrawDetail";
import withdrawHistory from "./pages/withdraw/withdrawHistory";
import Rejectwithdraw from "./pages/withdraw/Rejectwithdraw";
import Planpercentage from "./pages/Bidding/Planpercentage";
import BiddingHistory from "./pages/Bidding/BiddingHistory";
import Usermax from "./pages/Users/Usermax";
import UserDetails from "./pages/Users/UserDetails";
import DashboardInfo from "./pages/Dashboard/DashboardInfo";
import AdminAdd from "./pages/Admin/AdminAdd";
import AdminEdit from "./pages/Admin/AdminEdit";
import AdminList from "./pages/Admin/AdminList";
import PopupInfo from "./pages/CMS/PopupInfo";
import PendingHistory from "./pages/withdraw/PendingHistory";
import ChangePassword from "./pages/ChnagePass/ChangePassword";
import AdminAddUsers from './pages/Users/AdminAddusers'
import UserAdd from './pages/Users/AddUsers'
// import ForgotPage from "./pages/Forgot/ForgotPassword";

const history = createBrowserHistory();

const App = () => {
  const { isAuth } = store.getState().currentUser;

  useEffect(() => {
    if (isAuth != true && localStorage.admin_token) {
      decodeJwt(localStorage.admin_token, store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={globalStyle}>
        <ToastContainer />
        {/* <Router history={history} basename={process.env.REACT_APP_BASENAME || "/ulosdewvhref"}> */}
        <Router history={history} basename={"/bosticadmin"}>
          {/* <Router history={history} basename={"/"}> */}
          <Switch>
            <ConditionRoute path="/login" component={LoginPage} type={"auth"} />
            <ConditionRoute
              path="/change-password"
              component={ChangePassword}
              type={"private"}
            />
            <ConditionRoute
              path="/dashboard"
              component={Dashboard}
              layout={Admin}
              type={"private"}
              store={store}
            />
            <ConditionRoute
              path="/editprofile"
              component={EditProfile}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/setting"
              component={Settings}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/plan-details/:planid"
              component={Planedit}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/plan-details"
              component={Planupdate}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/withdrawrequest"
              component={withdrawDetail}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/withdrawhistory"
              component={withdrawHistory}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/rejectList"
              component={Rejectwithdraw}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/pending-list"
              component={PendingHistory}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/bidding-percentage"
              component={Planpercentage}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/bidding-history"
              component={BiddingHistory}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/users"
              component={Usermax}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/add-users"
              component={UserAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/profile-detaials"
              component={UserDetails}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/scroll-info"
              component={DashboardInfo}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/list"
              component={AdminList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/eidt/:userId"
              component={AdminEdit}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/add"
              component={AdminAdd}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/popup-info"
              component={PopupInfo}
              layout={Admin}
              type={"private"}
            />
                        <ConditionRoute
              exact
              path="/admin-added-users"
              component={AdminAddUsers}
              layout={Admin}
              type={"private"}
            />
            {/* <ConditionRoute exact path="/eidt/:userId" component={AdminEdit} layout={Admin} type={"private"} /> pending-list */}

            <Redirect from="/" to="/login" />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
