import axios from "axios";

// import action
import { decodeJwt } from "./jsonWebToken";

// import lib
import config from "../lib/config";
import setAuthToken from "../lib/setAuthToken";

import { SET_CURRENT_USER } from "../constant";

//LoginAction

export const login = async (data, dispatch) => {
  try {
    // // console.log(data,'dataa')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/login`,
      data,
    });

    localStorage.setItem("admin_token", respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getBiddinghistory = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getbiddinghis`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getadminDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/adminDetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updateProfile = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateAdminProfile`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const addNewAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/admin`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getAllAdmin = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/admin`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const deleteAdmin = async (id) => {
  console.log(id, "IDDDDDDDDDDDDDDD");
  try {
    let respData = await axios({
      method: "delete",
      url: `${config.baseUrl}/adminapi/deleteAdmin/${id}`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getSingleAdmin = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/singleAdmin/${id}`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const editAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.baseUrl}/adminapi/admin`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updateDashInfo = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateDashInfo`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

// export const forgotPassword = async (data) => {
//   try {
//       console.log(data,'aaaaa')
//       let respData = await axios({
//           'method': 'post',
//           'url': `${config.baseUrl}/adminapi/forgotPassword`,
//           data
//       });

//       return {
//           loading: false,
//           result: respData.data
//       }

//   }
//   catch (err) {
//       var sendErr = '';
//       if (err) {
//           sendErr = err;
//           if (err.response) {
//               sendErr = err.response;
//               if (err.response.data) {
//                   sendErr = err.response.data;
//                   if (err.response.data.errors) {
//                       sendErr = err.response.data.errors;
//                   }
//               }
//           }
//       }
//       return {
//           loading: false,
//           error: sendErr
//       }
//   }
// }

export const updateDashPopInfo = async (payload) => {
  console.log(payload, "Payoadddddd");
  try {
    var formData = new FormData();
    if (payload.image) {
      formData.append("image", payload.image);
    }
    if (payload.dashInfo) {
      formData.append("dashInfo", payload.dashInfo);
    }
    if (payload.id) {
      formData.append("id", payload.id);
    }
    if (payload.popup) {
      formData.append("popup", payload.popup);
    }

    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateDashPopInfo`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: formData,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const logout = (history) => {
  localStorage.removeItem("admin_token");
  history.push("/Login");
  setAuthToken("");
};

export const setCurrentUser = (decoded) => {
  console.log(decoded, "decodeddecodeddecoded");
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const updatesettings = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updatesettings`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getsettingsData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getupdatesettings`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getplanDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getplanDetail`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const Lockplandata = async (data) => {
  console.log(data, "datatatatat");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/lockplan`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getsingleplandata = async (id) => {
  console.log(id, "getsingleplandata");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getsingleplan/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const plandetailupdate = async (data) => {
  console.log(data, "datatatatat");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/Planupdate`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getwithdrawrequest = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getwithdrawdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getWithdrawhistory = async (data) => {
  console.log(data, "datadata");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getwithdrawHistoryData`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getrejectlist = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getrejectlist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getrejectPendinglist = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getrejectPendinglist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const rejecterequest = async (data) => {
  console.log(data, "datatatatat");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/rejectadmin`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const rejectePending = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/rejectPending`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const dclinePending = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/dclinePending`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
}

export const approveAdmin = async (data) => {
  console.log(data, "datatatatat");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/approveAdmin`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getcountApproved = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getcountApproved`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getDashInfo = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getDashInfo`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getDashPopInfo = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getDashPopInfo`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getBidPercentage = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getbidpercentage`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const UpdateplanPercetage = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/Planpercentage`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const AdminAddU = async () => {
  //adminaddedusr
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/adminaddedusr`,
      // data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getmaxuser = async (data) => {
  try {
    console.log(data, "AXios Dataaaaaaaaaaaaaaaaaaaa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/maxuser`,
      data,
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getUsers = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getUsers`,
      // data
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
      result1: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getWithdrwaCounts = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getWithdrawCnt`,
      // data
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
      result1: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getPackageWiseUser = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getPackagewiseUsr`,
      // data
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
      result1: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getEarningData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getEarnings`,
      // data
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getPaidAmounts = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getPaidAmts`,
      // data
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getChartData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getChartsdata`,
      // data
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getPackages = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getPackages`,
      // data
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const changepassword = async (data, dispatch) => {
  try {
    console.log(data, "dataa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/changePassward`,
      data,
    });
    //
    // localStorage.setItem("admin_token", respData.data.token);
    // setAuthToken(respData.data.token);
    // decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const checkOtp = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/checkOpt`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const UpdateUsers = async (data) => {
  console.log(data, "Check", config.baseUrl);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/register`,
      data: data,
    });
    return {
      loading: true,
      message: respData.data.message,
      status: respData.data.status,
      err: respData.data.err,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const getExitsUsers = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getExitUser`,
      data: data,
    });
    return {
      loading: true,
      message: respData.data.message,
      status: respData.data.status,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const getExitsRef = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getExitRef`,
      data: data,
    });
    return {
      loading: true,
      message: respData.data.message,
      status: respData.data.status,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const getPackagesRestric = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getPackageRestric`,
      data: data,
    });
    return {
      loading: true,
      message: respData.data.message,
      status: respData.data.status,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};
